import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';

import content from 'src/content/mediathek';

import subpageNavigations from 'src/data/subpage-navigations';

const MediathekPage = ({ data }) => {
  /*
  if there are no files (in wordpress) in a category, an empty page gets renderd for this category-page.
  to avoid, to get to this empty page, hrefs are replaced to link direkt to mediathek page
  */
  if (data.mainCategories && data.mainCategories.group) {
    const groups = data.mainCategories.group.map(group => group.fieldValue);
    subpageNavigations.mediathek.forEach((item, index) => {
      if (!groups.includes(item.name)) {
        subpageNavigations.mediathek[index].href = '';
      }
    });
  }

  return (
    <MainLayout
      title={content.title}
      headvisual={{
        image: data.headvisual,
        headline: content.headvisualHeadline
      }}
      subpageNavItems={subpageNavigations.mediathek}
    >
      <section>
        <p className="text-center mx-auto">
          <a href="https://admin.nbb.cc-n.dev/wp-content/uploads/2020/12/BA790_202011_p.pdf">
            AGBs der Nürnberger Business Beratungs GmbH
          </a>
        </p>
      </section>
    </MainLayout>
  );
};

export default MediathekPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "happy-wife-happy-life.jpg" }
    ) {
      ...image
    }
    mainCategories: allWordpressWpMedia(
      sort: { order: ASC, fields: acf___category }
    ) {
      group(field: acf___category) {
        fieldValue
      }
    }
  }
`;
